<template>
    <div>
      <v-card v-if="!sItem.dialog">
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        ><span class="text-h6">サムネイルの変更</span>
        </v-toolbar>
        <v-card-text>
          <v-container class="pa-0">
            <v-img
              :src=" (status0 | status1) ? img : altImg " 
              v-on:error="status0=false"  
              max-height="300"
              :aspect-ratop="16/9" 
            > 
            </v-img>

             <v-row
                class="text-center my-2 mx-0"
                height="70"
                @dragover.prevent
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onDrop"
              >
                <v-file-input
                  v-model="files"
                  accept="image/*"
                
                  height="70"
                  color="teal accent-4"
                  counter
               
                  label="ここにJPEGまたはPNGファイルをドロップまたはクリック"
                  placeholder=""
                  prepend-icon=""
                  outlined
                  :background-color="isDragging ? 'teal accent-4' : 'null'"
                  :rules="rules"
                  style="text-align:center;"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="teal accent-4" dark label small>{{ text }}</v-chip>
                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                    >+{{ files.length - 2 }} File(s)</span>
                  </template>
                </v-file-input>
              </v-row>
          </v-container>
        </v-card-text>
         
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2"
              outlined
              color="#78909C"   
              @click="close">キャンセル
          </v-btn>
          <v-btn 
            color="#1DE9B6"   
            class="white--text"
            :disabled="!changeEnable"
            @click="save"
            >変更
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card  v-else >
        <DialogStatus
          v-if="sItem.type"
          :item="sItem"
          v-on:closeEmit="close"
        />
      </v-card>
     
       
    </div>
      

</template> 

<script>

// import DialogError from './DialogError.vue'
import axios from "axios"
const DialogStatus = () => import('@/components/DialogStatus.vue');
export default {
  name: 'DialogThumbnailChange',
  components:{
    // DialogError,
    DialogStatus
  },
  props: ["thumbnail","id","type"],
  data () {
    return {
      img:this.thumbnail,
      status0:true,
      status1:false,
      sItem:{
        dialog:false,
        type:"",
        text:`通信中...`,
        loadText:"通信中...",
        compText:"サムネイルの変更が完了しました。",
        errorText:"エラーが発生しました。",
        close:true,
      },
      files: [],
      isDragging: false,
      dragCount: 0,
      dicName: (this.type=="navi") ? "naviName" : "fileName",
      dataName:(this.type=="navi") ? "NaviName" : "FileName",
      altImg:require("@/assets/navi.jpg"),
      rules: [
        files => !files ||  files.size <= 4_194_304 || 'サムネイルに使用できる画像の上限サイズは4MBです。'
      ],
    }
  },

  computed:{
    changeEnable(){
      if(this.files != [] && this.files != null && this.files.size/1024**2 <= 4 )  return true;
      return false;
    }
  },

  methods: {

    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      this.files = _files[0];
      // for (const file in _files) {
      //   if (!isNaN(file)) {
      //     //filesはファイル以外のデータが入っており、ファイルの場合のみキー名が数字になるため
      //     this.files =_files[file] ;
      //   }
      // }
    },
    
    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },

    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    },

    //サムネイルをAWSに保存
    save (){
      if(this.changeEnable){
        this.sItem.type = "load";
        this.sItem.dialog = true;
        axios({
          method: "PUT",
          url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${this.type}s/${this.id}/thumbnail`,
          headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"image/png"},
          data:this.files,
        }).then((response) => {
          if(response.data.results=="complete"){
              this.sItem.type = "comp";
              this.files = [];
              this.$emit('saveEmit',this.img)
          }else{
            this.sItem.type = "error";
            this.sItem.errorText = response.data;
          }
        }).catch(reason => {
          console.log(reason.response)
          this.sItem.type = "error";
          this.sItem.errorText = reason.response.data.message;
        });
      }else{
        this.sItem.dialog = true;
        this.sItem.type = "error";
        this.sItem.errorText = "ファイルがありません。"
      }
   
    
    },

    //画像の読み込み
    getBase64 (file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
      })
    },

    close (){
      this.img = this.thumbnail;
      this.sItem.dialog = false;
      this.files = [];
      this.$emit('closeEmit')
    }
 
  },

  watch : {
    files: function(newVal){
      if(newVal){
        this.getBase64(this.files)
        .then(image => this.img = image)
        this.status1=true
      }else{
        this.img=this.thumbnail
        this.status1 = false;
      }
    },
    thumbnail: function(){
      this.img=this.thumbnail
    }
  }
}

</script>
